<template>
    <el-dialog
        title="审批详情"
        :visible.sync="ApprovalDialogVisible"
        width="50%"
        top="5vh"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closeApprovalDialog()"
        v-loading.fullscreen.lock="login_loading"
    >
        <el-steps
            direction="vertical"
            v-for="(item,index) in data" :key="index"
        >
            <el-step :title="StepTitle(index,item)" :icon="StepIcon(index,item)" :status="StepStatus(index,item)">
                <template #description>
                    <el-card v-if="item.start_time">
                        <el-row :gutter="20" class="rowc">
                            <el-col :span="6">
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">发起人</el-col>
                                </el-row>
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">发起时间</el-col>
                                </el-row>
                                <el-row :gutter="20" class="rowc" v-if="item.note">
                                    <el-col :span="24" class="col_title">申请原因</el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="18">
                                <el-col :span="18">
                                    <el-row :gutter="20" class="rowc">
                                        <span class="col_content">{{item.start_staff_name}}</span>
                                    </el-row>
                                    <el-row :gutter="20" class="rowc">
                                        <span class="col_content">{{item.start_time}}</span>
                                    </el-row>
                                    <el-row :gutter="20" class="rowc">
                                        <span v-if="item.note" class="col_content" style="white-space: pre-line;">{{item.note===null?'无':item.note}}</span>
                                    </el-row>
                                </el-col>
                                <el-col :span="6" style="text-align: right;">
                                    <el-image
                                        style="width: 60px;height: 60px;"
                                        :src="$store.state.baseURL + item.head_portrait"
                                        fit="cover"
                                    >
                                    </el-image>
                                </el-col>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card v-else-if="(index < active) && (item.staff_id !==0) && (item.staff_id !== -1)">
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">审批人</el-col>
                                </el-row>
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">审批时间</el-col>
                                </el-row>
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">审批意见</el-col>
                                </el-row>
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title" v-if="item.note">审批说明</el-col>
                                </el-row>
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title" v-if="(item.other) && (item.other.length > 0)">附件</el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="18">
                                <el-col :span="18">
                                    <el-row :gutter="20" class="rowc">
                                        <span class="col_content">{{item.staff_name}}</span>
                                    </el-row>
                                    <el-row :gutter="20" class="rowc">
                                        <span class="col_content">{{item.time}}</span>
                                    </el-row>
                                    <el-row :gutter="20" class="rowc">
                                        <span :class="col_content_class(item)">{{item.opinion}}</span>
                                    </el-row>
                                    <el-row :gutter="20" class="rowc">
                                        <span v-if="item.note" class="col_content" style="white-space: pre-line;">{{item.note===null?'无':item.note}}</span>
                                    </el-row>
                                    <el-row :gutter="20" class="rowc">
                                        <common-upload-component
                                            v-show="item.other  && item.other.length"
                                            ref="upload"
                                            :ffileList="item.other"
                                            upload_url="user/approval_upload"
                                            :disabled="true"
                                        ></common-upload-component>
                                    </el-row>
                                </el-col>
                                <el-col :span="6" style="text-align: right;">
                                    <el-image
                                        style="width: 60px;height: 60px;"
                                        :src="$store.state.baseURL + item.head_portrait"
                                        fit="cover"
                                    >
                                    </el-image>
                                </el-col>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card v-else-if="(index < active) && (item.staff_id ===0)">
                        <el-row :gutter="20" :class="col_content_class(data[index-1])" style="text-align: center;">{{data[index-1] | zidongtongguo}}</el-row>
                    </el-card>
                    <el-card v-else-if="(index < active) && (item.staff_id ===-1)">
                        <el-row :gutter="20" class="rowc">
                            <el-col :span="6">
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">撤回人</el-col>
                                </el-row>
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">撤回时间</el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="18">
                                <el-col :span="18">
                                    <el-row :gutter="20" class="rowc">
                                        <span class="col_content">{{data | withdraw_staff_name}}</span>
                                    </el-row>
                                    <el-row :gutter="20" class="rowc">
                                        <span :span="18" class="col_content">{{item.time}}</span>
                                    </el-row>
                                </el-col>
                                <el-col :span="6" style="text-align: right;">
                                    <el-image
                                        style="width: 60px;height: 60px;"
                                        :src="withdraw_staff_head_portrait"
                                        fit="cover"
                                    >
                                    </el-image>
                                </el-col>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card v-else-if="index === active">
                        <el-row :gutter="20" class="rowc">
                            <el-col :span="6">
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">审批人</el-col>
                                </el-row>
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">审批状态</el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="18">
                                <el-col :span="18">
                                    <el-row :gutter="20" class="rowc">
                                        <span class="col_content">{{item.staff_name}}</span>
                                    </el-row>
                                    <el-row :gutter="20" class="rowc">
                                        <span :span="18" class="col_content_ing">审批中</span>
                                    </el-row>
                                </el-col>
                                <el-col :span="6" style="text-align: right;">
                                    <el-image
                                        style="width: 60px;height: 60px;"
                                        :src="$store.state.baseURL + item.head_portrait"
                                        fit="cover"
                                    >
                                    </el-image>
                                </el-col>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card v-else>
                        <el-row :gutter="20" class="rowc">
                            <el-col :span="6">
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">审批人</el-col>
                                </el-row>
                                <el-row :gutter="20" class="rowc">
                                    <el-col :span="24" class="col_title">审批状态</el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="18">
                                <el-col :span="18">
                                    <el-row :gutter="20" class="rowc">
                                        <span class="col_content">{{item.staff_name}}</span>
                                    </el-row>
                                    <el-row :gutter="20" class="rowc">
                                        <span :span="18" class="col_content_wait">待审批</span>
                                    </el-row>
                                </el-col>
                                <el-col :span="6" style="text-align: right;">
                                    <el-image
                                        style="width: 60px;height: 60px;"
                                        :src="$store.state.baseURL + item.head_portrait"
                                        fit="cover"
                                    >
                                    </el-image>
                                </el-col>
                            </el-col>
                        </el-row>
                    </el-card>
                </template>
            </el-step>
        </el-steps>
        <fieldset class="fieldsetc" v-if="ApprovalAuth">
            <legend class="legendc">审批</legend>
                <el-form
                    :model="form"
                    label-width="100px"
                    size="small"
                >
                    <el-row :gutter="20" style="margin-top: 20px">
                        <el-col :span="6" style="text-align: right;color:cornflowerblue">审批意见：</el-col>
                        <el-col :span="18">
                            <el-radio-group v-model="form.opinion" size="medium" :fill="opinion_color">
                                <el-radio-button label="同意"></el-radio-button>
                                <el-radio-button label="拒绝"></el-radio-button>
                                <el-radio-button label="转审"></el-radio-button>
                            </el-radio-group>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" style="margin-top: 20px" v-if="form.opinion==='转审'">
                        <el-col :span="6" style="text-align: right;color:cornflowerblue">转审人：</el-col>
                        <el-col :span="18">
                            <el-select
                                v-model="form.go_staff_id"
                                filterable
                                clearable
                                placeholder="选择转审人"
                                class="go_staff_idc"
                                size="small"
                            >
                                <el-option
                                    v-for="item in AllStaff"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" style="margin-top: 20px">
                        <el-col :span="6" style="text-align: right;color:cornflowerblue">审批说明：</el-col>
                        <el-col :span="18">
                            <el-input type="textarea" :rows="3" v-model="form.note" style="width: 100%"></el-input>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" style="margin-top: 20px">
                        <el-col :span="6" style="text-align: right;color:cornflowerblue">附件：</el-col>
                        <el-col :span="18">
                            <common-upload-component
                                ref="upload"
                                :ffileList="form.other"
                                upload_url="user/approval_upload"
                            ></common-upload-component>
                        </el-col>
                    </el-row>
                </el-form>
        </fieldset>
        <div slot="footer">
            <el-button v-if="ApprovalAuth" type="success" plain @click="submitApproval('ApprovalForm')">审批</el-button>
            <el-button plain @click="closeApprovalDialog()">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { avatar_list_request,staff_list_request } from '@/network/list.js'
import CommonUploadComponent from './CommonUploadComponent'

export default {
    name:'CommonApprovalDialog',
    data(){
        return {
            active: 999,
            form: {
                opinion: '同意',
                note: '',
                other: [],
                go_staff_id: ''
            },
            login_loading: false,
            AllAvatar: [],
            AllStaff: []
        }
    },
    computed:{
        opinion_color() {
            if(this.form.opinion === '同意') {
                return '#00DB00'
            }
            if(this.form.opinion === '拒绝') {
                return '#FF0000'
            }
            if(this.form.opinion === '转审') {
                return '#FF8000'
            }
            
            return '#FFFFFF'
        },
        withdraw_staff_head_portrait() {
            return this.$store.state.baseURL + this.data[0]['head_portrait']
        }
    },
    methods:{
        closeApprovalDialog() {
            this.form = {
                opinion: '同意',
                note: '',
                other: [],
                go_staff_id: ''
            },
            this.active = 999
            this.$emit('exitApprovalDialog')
        },
        StepTitle(index,item) {
            if(item.start_time) {
                return '发起审批'
            } else if(item.staff_id === 0) {
                return '审批结果'
            } else if(item.staff_id === -1) {
                return '审批撤回'
            } else {
                return '审批流程'
            }
        },
        StepIcon(index,item) {
            if(item.start_time) {
                return 'el-icon-s-promotion'
            } else if((index < this.active) && (item.staff_id !==0) && (item.staff_id !==-1)) {
                //return 'el-icon-circle-check'
            } else if((index < this.active) && (item.staff_id ===0)) {
                return 'el-icon-s-check'
            } else if((index < this.active) && (item.staff_id ===-1)) {
                return 'el-icon-warning-outline'
            } else if(index === this.active) {
                return 'el-icon-s-flag'
            } else {
                return 'el-icon-loading'
            }
        },
        StepStatus(index,item) {
            if(item.start_time) {
                return 'process'
            } else if((index < this.active) && (item.staff_id !==0) && (item.staff_id !==-1)) {
                if((item.opinion === '同意') || (item.opinion === '转审')) {
                    return 'success'
                } else {
                    return 'error'
                }
            } else if((index < this.active) && (item.staff_id ===0)) {
                return 'process'
            } else if((index < this.active) && (item.staff_id ===-1)) {
                return 'process'
            } else if(index === this.active) {
                return 'finish'
            } else {
                return 'wait'
            }
        },
        col_content_class(item) {
            if(item.opinion === '同意') {
                return 'col_content_yes'
            }
            if(item.opinion === '拒绝') {
                return 'col_content_no'
            }
            if(item.opinion === '转审') {
                return 'col_content_go'
            }
            if(item.staff_id === -1) {
                return 'col_content_withdraw'
            }
            return 'col_content_yes'
        },
        submitApproval() {
            this.login_loading = true
            this.form.id = this.id
            this.form.other = this.$refs.upload.fileList
            
            this.ApprovalRequest(this.form)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('已审批!')
                        this.refresh()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
                .finally(() => this.closeApprovalDialog())
        },
        refresh() {
            this.$emit('refresh')
        },
        get_all_avatar() {
            avatar_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllAvatar = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
    },
    filters:{
        zidongtongguo(v) {
            if(v.opinion) {
                if(v.opinion === '同意') {
                    return '通过'
                } else if(v.opinion === '拒绝') {
                    return '驳回'
                }
            }

            if(v.staff_id === -1) {
                return '撤回'
            }

            return '自动通过'
        },
        withdraw_staff_name(v) {
            return v[0]['start_staff_name']
        }
    },
    props:{
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        ApprovalDialogVisible: Boolean,
        data: {
            type: Array,
            default() {
                return []
            }
        },
        current_examine_staff_id: {
            type: Number,
            default() {
                return 0
            }
        },
        ApprovalAuth: Boolean,
        ApprovalRequest: Function
    },
    created(){
        this.get_all_avatar()
        this.get_all_staff()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CommonUploadComponent
    },
    watch:{
        data(newVal) {
            let status = true
            newVal.forEach((v,i) => {
                if((i > 0) && (v.time === null) && status) {
                    this.active = i
                    status = false
                }

                this.AllAvatar.forEach((vv,ii) => {
                    if((v.start_staff_id === vv.id) || (v.staff_id === vv.id)) {
                        v.head_portrait = vv.head_portrait
                    }
                })
            })
        }
    }
}
</script>

<style lang='less'>
.col_title {
    color:cornflowerblue;
    font-size: 15px;
}
.col_content {
    font-size: 15px;
}
.col_content_yes {
    font-size: 15px;
    color: #00BB00;
    font-weight:bold;
}
.col_content_no {
    font-size: 15px;
    color: #CE0000;
    font-weight: bold
}
.col_content_go {
    font-size: 15px;
    color: #FF8000;
    font-weight:bold;
}
.col_content_withdraw {
    font-size: 15px;
    color: hsl(271, 31%, 51%);
    font-weight: bold
}
.col_content_ing {
    font-size: 15px;
    color: #2828FF;
    font-weight: bold
}
.col_content_wait {
    font-size: 15px;
    color: #C0C4CC;
    font-weight: bold
}
.rowc {
    margin-bottom: 10px;
}
.el-step__description.is-process {
    width: 100%;
}
.fieldsetc {
    margin-top: 20px;
    min-width: 95%;
    min-width: border-box;
}
.legendc {
    color: #921AFF;
}
.go_staff_idc {
    width: 100%;
}

</style>