<template>
    <fieldset class="fieldsetc">
        <legend class="legendc">{{title}}</legend>
        <el-upload
        class="upload-demo"
        :action="this.$store.state.baseURL + upload_url"
        :on-exceed="handleExceed"
        :limit="20"
        :on-preview="handlePreview"
        :before-upload="beforeUpload"
        :before-remove="beforeRemove"
        :on-remove="onRemove"
        :on-success="onSuccess"
        :file-list="fileList"
        list-type="text"
        :headers="headers"
        :disabled="disabled">
            <div>
                <el-button v-if="!disabled" size="small" type="primary">上传附件</el-button>
                <span v-if="!disabled" style="margin-left:10px;color:#2828FF">支持上传.png|.jgp|.doc|.xls|.ppt|.pdf|.txt七种格式的文件</span>
            </div>
            <div v-html="note"></div>
        </el-upload>
        <el-dialog
            :visible.sync="dialogVisible"
            :center="true"
            :append-to-body="true"
        >
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </fieldset>
</template>

<script>
export default {
    name:'CommonUploadComponent',
    data(){
        return {
            fileList: [],
            dialogVisible: false,
            dialogImageUrl: '',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('x-token')
            }
        }
    },
    computed:{},
    methods:{
        onSuccess(response, file, fileList) {
            if(response.status !==0) {
                this.$message.error(response.msg)
                return
            }

            this.fileList.push({
                name: file.name,
                url: this.$store.state.baseURL + response.result.url
            })
        },
        handlePreview(file) {
            const name_arr = file.name.split('.');
            if((name_arr[name_arr.length-1] === 'jpg') || (name_arr[name_arr.length-1] === 'png')) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            } else {
                window.open(file.url, '_blank')
            }
        },
        handleExceed(files, fileList) {
            this.$message.warning(`最大允许上传20个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },
        onRemove(file,fileList) {
            this.fileList = fileList
        },
        beforeUpload(file) {
            const arr = [
                'image/jpeg',   //JPG图片
                'image/png',    //PNG图片
                'application/msword',   //doc格式word
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',   //docx格式word
                'application/vnd.ms-excel',     //xls格式表格
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',    //xlsx格式表格
                'application/vnd.ms-powerpoint',    //ppt格式幻灯片
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',    //pptx格式幻灯片
                'text/plain',   //txt文本
                'application/pdf'   //pdf
            ]

            const InArr = (arr.indexOf(file.type) > -1)
            const OverStepSize = (file.size / 1024 / 1024 < 30)

            if (!InArr) {
                this.$message.error('超出可上传文件类型!');return;
            }
            if (!OverStepSize) {
                this.$message.error('超出可上传文件大小');return;
            }

            return InArr && OverStepSize;
        }
    },
    filters:{},
    props:{
        upload_url: {
            type: String,
            default() {
                return ''
            }
        },
        ffileList: {
            type: Array,
            default() {
                return []
            }  
        },
        disabled: {
            type: Boolean,
            default() {
                return false
            }
        },
        title: {
            type: String,
            default() {
                return '附件信息'
            }
        },
        note: {
            type: String,
            default() {
                return ''
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        ffileList(newVal) {
            this.fileList = newVal
        }
    }
}
</script>

<style lang='less'></style>